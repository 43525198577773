import { Interactive, useHitTest, useXR, useXREvent } from '@react-three/xr';
import Hammer from 'hammerjs';
import { useEffect, useRef, useState } from 'react';
import { Vector3, Euler } from 'three';
import {
  buttonIsBeingTouched,
  dreamerIsBeingTouched,
  setButtonIsBeingTouched,
  setDreamerIsBeingTouched,
} from '../handlers';

import {
  findSurfaceOnSelect,
  onHoverModel,
  onBlurModel,
  touchDownFunction,
  touchUpFunction,
} from '../handlers/';
import { mouseMove } from '../handlers/clickScreen';
import { Model } from './Model';

export let twoFingers = false;

export const dreamerSize = {
  sxs: [0.0005, 0.0005, 0.0005],
  xsm: [0.003, 0.003, 0.003],
  sm: [0.0075, 0.0075, 0.0075],
  md: [0.01, 0.01, 0.01],
  lg: [0.02, 0.02, 0.02],
};

export function dreamerResize(scene, dreamerSizeState, setDreamerSizeState) {
  setButtonIsBeingTouched(true);

  const size =
    dreamerSizeState === 'lg'
      ? 'md'
      : dreamerSizeState === 'md'
      ? 'sm'
      : dreamerSizeState === 'sm'
      ? 'xsm'
      : dreamerSizeState === 'sxs'
      ? 'lg'
      : 'sxs';
  scene.scale.set(
    dreamerSize[size][0],
    dreamerSize[size][1],
    dreamerSize[size][2]
  );
  setDreamerSizeState(size);
}

export function ARInteractive({
  scene,
  findSurface,
  setFindSurface,
  size,
  mainCamera,
}) {
  const { isPresenting, player } = useXR();

  useXREvent('selectend', (e) => {
    if (dreamerIsBeingTouched && findSurface) {
      setFindSurface(true);
    }
    if (!buttonIsBeingTouched && findSurface && !dreamerIsBeingTouched) {
      setFindSurface(false);
    }

    setDreamerIsBeingTouched(false);
    setButtonIsBeingTouched(false);
  });

  useEffect(() => {
    var hammertime = new Hammer(document.body);
    if (isPresenting) {
      hammertime.get('pinch').set({
        enable: true,
        enable_only_in_multi_touch: true,
        threshold: 0.1,
      });
      hammertime.get('rotate').set({
        enable: true,
        enable_only_in_multi_touch: true,
        threshold: 1,
      });
      hammertime.on('pinch', (e) => {
        twoFingers = true;
        setFindSurface(false);
        if (scene.scale.x >= 0.00005 && scene.scale.x <= 1) {
          scene.scale.set(
            scene.scale.x - 0.00025 * e.velocityY,
            scene.scale.y - 0.00025 * e.velocityY,
            scene.scale.z - 0.00025 * e.velocityY
          );
          if (scene.scale.x <= 0.00005) {
            scene.scale.set(0.00009, 0.00009, 0.00009);
          }
          if (scene.scale.x >= 1) {
            scene.scale.set(1, 1, 1);
          }
        }
      });

      hammertime.on('rotate', (e) => {
        twoFingers = true;
        setFindSurface(false);
        scene.rotation.y += 0.4 * e.velocityX;
      });
      hammertime.on('rotateend', (e) => {
        twoFingers = false;
      });
      hammertime.on('pinchend', (e) => {
        twoFingers = false;
      });
      document.addEventListener('touchstart', (event) =>
        touchDownFunction(event, mainCamera, scene, setFindSurface)
      );
      document.addEventListener('touchend', (event) =>
        touchUpFunction(event, mainCamera, scene, setFindSurface)
      );
      // document.addEventListener(
      //   'touchmove',
      //   (event) =>
      //     mouseMove(event, mainCamera, scene, setFindSurface, findSurface),
      //   false
      // );
    } else {
      // document.removeEventListener('touchstart', touchDownFunction, (event) =>
      //   touchDownFunction(event, mainCamera, scene)
      // );
      // document.removeEventListener('touchend', (event) =>
      //   touchUpFunction(event, mainCamera, scene, setFindSurface)
      // );
      // document.removeEventListener(
      //   'touchmove',
      //   (event) => mouseMove(event, mainCamera, scene, setFindSurface),
      //   false
      // );
    }
  }, [isPresenting, mainCamera, scene, setFindSurface]);

  return (
    <>
      <Interactive
        onSelect={() => findSurfaceOnSelect(findSurface, setFindSurface)}
        onHover={() => onHoverModel(scene, findSurface, setFindSurface)}
        onBlur={onBlurModel}
      >
        <Model
          scene={scene}
          findSurface={findSurface}
          size={size}
          camera={mainCamera}
        />
      </Interactive>
    </>
  );
}
