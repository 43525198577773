import { renderer } from '../components/dreamer';

export async function saveAsImage(render, size, camera) {
  let name = undefined;
  const tokenId = window.location.pathname.split('/')[1];
  if (typeof render === 'string') {
    name = render;
    render = undefined;
  }

  var imgData, imgNode;

  try {
    var strMime = 'image/png';
    const canvas = document.querySelector('canvas');

    imgData = render
      ? render?.domElement.toDataURL(strMime)
      : canvas.toDataURL(strMime);

    await saveFile(
      imgData.replace(strMime, 'image/octet-stream'),
      `${name || 'Snapshot'}_${tokenId}.png`
    );
  } catch (e) {
    console.log(e);
    return e;
  }
}

const saveFile = async function (strData, filename) {
  var link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = strData;
    link.click();
    document.body.removeChild(link);
  }
};
