import { useRef } from "react";
import { useFrame } from '@react-three/fiber'

import { Plane } from "@react-three/drei";


const Skybox = ({
    skybox,
    groundColor,
    selectedSkybox,
    backgroundData
}) => {

    const ref = useRef()

    //slow rotation
    //too fast = dizzy
    useFrame((state, delta) => (ref.current.rotation.y += 0.0001))

    return (
        <>
            {/* sky */}
            <primitive
                ref={ref}
                position={[0, 37, 0]}
                object={skybox.scene}
                scale={[0.45, 0.45, 0.45]}
            />

            {/* ground */}
            <Plane
                receiveShadow={true}
                rotation={[-Math.PI / 2, 0, 0]}
                position={[0, 0, 0]}
                args={[300, 300]}
            >
            {/* color variable to match background. Make ENUM for this? / global object*/}
                <meshStandardMaterial attach="material" color={(selectedSkybox != null) ? groundColor : '#ffffff'} />
            </Plane>
        </>
    )
}

export default Skybox;