import { Raycaster, Vector2, Vector3 } from 'three';
import {
  setScreenIsBeingTouched,
  screenIsBeingTouched,
  dreamerIsBeingTouched,
  setDreamerIsBeingTouched,
} from '.';
import { twoFingers } from '../components/ARInteractive';
import { raycaster, setRayCaster } from '../components/dreamer';

export let screenX;
export let screenY;

export let mouseMovementX;
export let mouseMovementY;
export let mouse = new Vector2();
export let raycastOrigins;
export let findSurfaceNoXandY = false;

let moving = false;
let rotating = false;
let scaling = false;

export function swipedetect(el, callback) {
  var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 150, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function (swipedir) {};

  touchsurface.addEventListener(
    'touchstart',
    function (e) {
      var touchobj = e.changedTouches[0];
      swipedir = 'none';
      distX = 0;
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
      //  e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    'touchmove',
    function (e) {
      //  e.preventDefault(); // prevent scrolling when inside DIV
    },
    false
  );

  touchsurface.addEventListener(
    'touchend',
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? 'left' : 'right'; // if dist traveled is negative, it indicates left swipe
        } else if (
          Math.abs(distY) >= threshold &&
          Math.abs(distX) <= restraint
        ) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? 'up' : 'down'; // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir);
      //  e.preventDefault();
    },
    false
  );
}

export function touchDownFunction(event, camera, scene, setFindSurface) {
  // raycastOrigins = new Raycaster(camera.position, camera.getWorldDirection());
  // moving = false;
  // rotating = true;
  // scaling = true;
  // moveModel(event, camera, scene);
  // findSurfaceNoXandY = true;
  // setScreenIsBeingTouched(true);
  //setDreamerIsBeingTouched(true);

  setTimeout(() => {
    if (!dreamerIsBeingTouched && !twoFingers) setFindSurface(true);
  }, 500);
}
export function touchUpFunction(event, camera, scene, setFindSurface) {
  // moveModel(event, camera, scene);
  // setFindSurface(false);
  // moving = false;
  // findSurfaceNoXandY = false;
  // setScreenIsBeingTouched(false);

  // rotating = false;
  // scaling = false;
  setFindSurface(false);
  setDreamerIsBeingTouched(false);
}
export function blurFunction() {}

export function mouseMove(event, camera, scene, setFindSurface, findSurface) {
  if (
    screenIsBeingTouched &&
    (dreamerIsBeingTouched || moving || rotating || scaling)
  ) {
    moving = true;
    setFindSurface(true);
    findSurfaceNoXandY = true;
    if (event.touches.length === 1 && moving)
      moveModel(event, camera, scene, findSurface);

    //detect horizontal pinch for rotation of 3D model. Then detect vertical pinch for scaling of 3D model.

    if (event.touches.length === 2 && rotating) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const dist =
        Math.sqrt(
          Math.pow(touch1.clientX - touch2.clientX, 2) +
            Math.pow(touch1.clientY - touch2.clientY, 2)
        ) / 2;
      const distDiff = dist - raycaster.previousDist;
      raycaster.previousDist = dist;
      raycaster.rotate(distDiff);
    } else if (event.touches.length === 2 && scaling) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const dist =
        Math.sqrt(
          Math.pow(touch1.clientX - touch2.clientX, 2) +
            Math.pow(touch1.clientY - touch2.clientY, 2)
        ) / 2;
      const distDiff = dist - raycaster.previousDist;
      raycaster.previousDist = dist;
      raycaster.scale(distDiff);
    }
  }
  // } else if (event.touches.length > 1) {
  //   if (event.touches[0].clientX - event.touches[1].clientX > 0) {
  //     rotating = true;
  //     scaling = false;
  //     let touch1 = event.touches[0];
  //     let touch2 = event.touches[1];
  //     let x1 = touch1.clientX;
  //     let x2 = touch2.clientX;
  //     let y1 = touch1.clientY;
  //     let y2 = touch2.clientY;
  //     let xDiff = x2 - x1;
  //     let yDiff = y2 - y1;
  //     let angle = Math.atan2(yDiff, xDiff);

  //     scene.rotation.set(
  //       scene.rotation.x,
  //       scene.rotation.y + angle / 90,
  //       scene.rotation.z
  //     );
  //     //detect vertical pinch
  //   } else if (event.touches[0].clientX - event.touches[1].clientX < 0) {
  //     rotating = true;
  //     scaling = false;
  //     let touch1 = event.touches[0];
  //     let touch2 = event.touches[1];
  //     let x1 = touch1.clientX;
  //     let x2 = touch2.clientX;
  //     let y1 = touch1.clientY;
  //     let y2 = touch2.clientY;
  //     let xDiff = x2 - x1;
  //     let yDiff = y2 - y1;
  //     let angle = Math.atan2(yDiff, xDiff);

  //     scene.rotation.set(
  //       scene.rotation.x,
  //       scene.rotation.y - angle / 90,
  //       scene.rotation.z
  //     );
  //   } else if (
  //     event.touches[0].clientY > event.touches[1].clientY ||
  //     event.touches[0].clientY < event.touches[1].clientY
  //   ) {
  //     scaling = true;
  //     rotating = false;
  //     //scale model on vertical movement
  //     let touch1 = event.touches[0];
  //     let touch2 = event.touches[1];
  //     let y1 = touch1.clientY;
  //     let y2 = touch2.clientY;
  //     let x1 = touch1.clientX;
  //     let x2 = touch2.clientX;
  //     let yDiff = y2 - y1;
  //     let xDiff = x2 - x1;
  //     if (yDiff > 0) {
  //       if (scene.scale.y > 0.01) {
  //         scene.scale.set(
  //           scene.scale.x + 0.0001,
  //           scene.scale.y + 0.0001,
  //           scene.scale.z + 0.0001
  //         );
  //       }
  //     } else {
  //       if (scene.scale.y < 0.01) {
  //         // scene.scale.set(
  //         //   scene.scale.x - 0.0001,
  //         //   scene.scale.y - 0.0001,
  //         //   scene.scale.z - 0.0001
  //         // );
  //       }
  //     }
  //   }
  // }
}

function moveModel(event, camera, scene, findSurface) {
  var vec = new Vector3();

  if (event.touches.length > 0 && moving) {
    mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.touches[0].clientY / window.innerHeight);
    raycaster.setFromCamera(mouse, camera);
    var vector = new Vector3();

    vec.set(mouse.x, mouse.y, raycaster.ray.direction.z);
    const zDirection = raycaster.ray.direction.z;

    if (!findSurface) {
      // scene.position.set(mouse.x, mouse.y, raycaster.ray.direction.z);

      //scene.decompose(vec, scene.rotation, scene.scale);
      const direction = camera.getWorldDirection(vector);
      if (direction.x > 0) {
        scene.position.set(mouse.x, mouse.y, raycaster.ray.direction.z);
      } else {
        scene.position.set(raycaster.ray.direction.z, mouse.y, mouse.x);
      }

      //move model to mouse position
      if (camera.position.z > -1) {
      } else {
        scene.position.set(zDirection, mouse.y, mouse.x);
      }

      // camera.position.set(mouse.x, mouse.y, raycaster.ray.origin.z);

      //scene.rotation.set(scene.rotation.x, scene.rotation.y, scene.rotation.z);
    }
  }
}
