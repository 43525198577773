export {
  touchUpFunction,
  touchDownFunction,
  blurFunction,
} from './clickScreen';
export {
  onHoverModel,
  findSurfaceOnSelect,
  onBlurModel,
} from './modelInteraction';

export let buttonIsBeingTouched = false;
export let dreamerIsBeingTouched = false;
export let screenIsBeingTouched = false;

export function setButtonIsBeingTouched(bool) {
  buttonIsBeingTouched = bool;
}

export function setDreamerIsBeingTouched(bool) {
  dreamerIsBeingTouched = bool;
}

export function setScreenIsBeingTouched(bool) {
  screenIsBeingTouched = bool;
}
