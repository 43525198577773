import { Box, Text } from '@react-three/drei';
import { Interactive } from '@react-three/xr';
import { useState } from 'react';
import { Vector3 } from 'three';

const pointerL = {
  ignore: false,
  prev: new Vector3(),
  position: new Vector3(),
  force: 0,
};

const pointerR = {
  ignore: false,
  prev: new Vector3(),
  position: new Vector3(),
  force: 0,
};

export function Button({ children, onSelect, position, rotation }) {
  const [hover, setHover] = useState(false);

  return (
    <Interactive
      onSelect={onSelect}
      onHover={(e) => {
        setHover(true);
        const pointer =
          e.controller.inputSource.handedness === 'left' ? pointerL : pointerR;
        pointer.ignore = true;
      }}
      onBlur={(e) => {
        setHover(false);
        const pointer =
          e.controller.inputSource.handedness === 'left' ? pointerL : pointerR;
        pointer.ignore = false;
      }}
    >
      <Box args={[0.12, 0.05, 0.01]} position={position} rotation={rotation}>
        <meshPhongMaterial color={hover ? '#669' : '#666'} wireframe />
        <Text fontSize={0.03} position={[0, 0, 0.006]}>
          {children}
        </Text>
      </Box>
    </Interactive>
  );
}
