import { useState, useEffect, createElement } from 'react';
import { isMobile } from 'react-device-detect';

import Select from 'react-select';

import QRCode from 'qrcode';

//Local modules

import { saveAsImage, createPFP, domainFromUrl } from '../functions';

function ControlOverlay({
  selectAnimation,
  currentAnimation,
  animationNames,

  selectSkybox,
  currentSkybox,
  backgroundNames,

  mainCamera,
  setTargetY,

  //animation pause / play toggle
  setIsPaused,
  isPaused,

  blob,
  origin,
  //grab api key.
  setKey,
}) {
  const [skyboxOptions, setSkyboxOptions] = useState([]);
  const [animationOptions, setAnimationOptions] = useState([]);
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    let forSkybox = backgroundNames.map((item, index) => {
      return { value: index, label: item };
    });

    let forAnimation = animationNames.map((item, index) => {
      return { value: index, label: item.optionName };
    });

    setSkyboxOptions(forSkybox);
    setAnimationOptions(forAnimation);
  }, []);

  useEffect(() => {
    (async () => {
      const qrCodeURL = await QRCode.toDataURL(
        `${window.location.href}?qr=true&skybox=${currentSkybox}&animation=${currentAnimation}`
      );
      setQrCode(qrCodeURL);
    })();
  }, [currentSkybox, currentAnimation]);

  function handleBackgroundChange(option) {
    selectSkybox(option.value);

    setShowControls(false);
  }

  function handleAnimationChange(option) {
    selectAnimation(option.value);

    setShowControls(false);
  }

  //UI Logic
  const [showControls, setShowControls] = useState(false);

  function handleControlToggle() {
    setShowControls(!showControls);
  }

  function CloneButton({ text, onClick }) {
    const el = document.getElementById('ARButton');
    if (!el) return null;

    const button = createElement(
      'button',
      {
        style: {
          bottom: '0px',
          cursor: 'pointer',
          padding: '12px 6px',
          border: '1px solid rgb(255, 255, 255)',
          borderRadius: '4px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'rgb(255, 255, 255)',
          font: '13px sans-serif',
          textAlign: 'center',
          opacity: 0.5,
          outline: 'none',
          zIndex: 0,
          width: '100%',
        },
        onClick: onClick
          ? onClick
          : () => {
              el.click();
            },
      },
      text
    );

    return (
      <div
        style={{
          paddingBottom: 50,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        {button}
      </div>
    );
  }

  return (
    <div className='ui-con'>
      <img
        className='control-button'
        src='/vx-options-button.svg'
        alt='Options'
        height='30px'
        width='30px'
        onClick={handleControlToggle}
      />

      {showControls && (
        <div className='controls-wrapper'>
          <h3 className='control-title'>Background</h3>
          <Select
            value={skyboxOptions[currentSkybox]}
            options={skyboxOptions}
            onChange={handleBackgroundChange}
            isSearchable={false}
          />

          <div className='spacer'></div>

          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <h3 className='control-title'>Animation</h3>

            <button
              className='pause-play-button'
              onClick={() => setIsPaused(!isPaused)}
            >
              <img
                src={
                  !isPaused ? 'pause-button-image.svg' : 'play-button-image.svg'
                }
                alt='Pause / Play Animation'
                height='12px'
                width='12px'
              />
            </button>
          </span>

          <Select
            value={animationOptions[currentAnimation]}
            options={animationOptions}
            onChange={handleAnimationChange}
            isSearchable={false}
          />
          {origin !== 'opensea.io' && (
            <>
              <div className='d-flex justify-content-center'>
                <CloneButton text='Snapshot' onClick={() => saveAsImage()} />
                <CloneButton
                  text='PFP'
                  onClick={() => createPFP(mainCamera, setTargetY)}
                />
              </div>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <h3 className='control-title'>Save GLTF</h3>

                <button
                  className='download-button'
                  onClick={() => {
                    let tokenId = window.location.pathname.substring(1);
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `dreamer_${tokenId}.gltf`;
                    a.click();
                  }}
                >
                  <img
                    style={{
                      filter: 'brightness(0) invert(1)',
                      WebkitFilter: 'brightness(0) invert(1)',
                    }}
                    src='download-button.svg'
                    alt='Save GLTF'
                    height='12px'
                    width='12px'
                  />
                </button>
              </span>
            </>
          )}

          <div className='spacer'></div>

          <h3 className='control-title'>{isMobile ? '' : 'view AR'}</h3>
          {!isMobile ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <img className='ml-4' src={qrCode} alt='qrcode' />
            </div>
          ) : (
            <CloneButton text='Enter AR' />
          )}
        </div>
      )}
    </div>
  );
}

export default ControlOverlay;
