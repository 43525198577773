// react
import { useState, useRef } from 'react';

// fiber
import { useFrame } from '@react-three/fiber';

// drei
import { useAnimations, useGLTF } from '@react-three/drei';

// other
import { useEffect } from 'react';
import { useXR } from '@react-three/xr';
import { Button } from '../hooks';
import { ARInteractive, dreamerSize } from './ARInteractive';
import { dreamerIsBeingTouched, screenIsBeingTouched } from '../handlers';

import { Raycaster } from 'three';

export let raycaster = new Raycaster();

export function setRayCaster(rc) {
  raycaster = rc;
}

export let renderer;

function resetDreamerVX(scene) {
  scene.scale.set(0.005, 0.005, 0.005);
  scene.position.set(0, -0.5, -1);
}

function Dreamer({
  //deconstructed props
  model,
  animationNames,
  selectedAnimation,
  isPaused,
  setMainScene,
  setMainCamera,
  mainCamera,
  //...
}) {
  //load animations for later use
  const { scene, animations, camera } = useGLTF(model);
  const { actions } = useAnimations(animations, scene);

  const prevAnimationRef = useRef();

  const { isPresenting, player } = useXR();

  const [buttonPos, setButtonPos] = useState([0, -4, 0]);
  const [buttonRotations, setButtonRotations] = useState([0, 0, 0]);
  const [findSurface, setFindSurface] = useState(false);

  const [dreamerSizeState, setDreamerSizeState] = useState('xsm');

  useFrame((state) => {
    let camera = state.camera;

    if (!raycaster) raycaster = state.raycaster;
    let mouse = state.mouse;

    if (!renderer) renderer = state.gl;

    setMainCamera(player.children[0]);

    let positionArray = scene.position.toArray();

    if (dreamerIsBeingTouched && screenIsBeingTouched) {
    }

    if (
      buttonPos !== positionArray ||
      scene.rotation.x !== buttonRotations[0] ||
      scene.rotation.z !== buttonRotations[2]
    ) {
      setButtonPos(positionArray);
      setButtonRotations([
        scene.rotation.x,
        scene.rotation.y + Math.PI,
        scene.rotation.z,
      ]);
    }
  });

  useEffect(() => {
    if (scene) {
      scene.traverse(function (object) {
        if (object.isMesh) {
          object.castShadow = true;
          object.receiveShadow = true;
        }
      });
    }
  }, [scene]);

  useEffect(() => {
    setMainScene(scene);

    if (isPresenting) {
      resetDreamerVX(scene);
    } else {
      scene.scale.set(0.04, 0.04, 0.04);
      scene.position.set(0, 0, 0);
      scene.rotation.set(0, 3.1, 0);
    }
  }, [isPresenting, scene]);

  useEffect(() => {
    if (actions) {
      // because it's an index can't just check for plain existance
      // index 0 is falsy. So check for literal null instead
      if (prevAnimationRef.current != null) {
        // if previous animation exists.. stop it
        actions[animationNames[prevAnimationRef.current].animationName].stop();
      }

      // save current animation to be used above on next fire
      prevAnimationRef.current = selectedAnimation;

      // play new selected animation after stopping previous
      actions[animationNames[selectedAnimation].animationName].play();
    }
  }, [selectedAnimation]);

  useEffect(() => {
    actions[animationNames[selectedAnimation].animationName].paused = isPaused;
  }, [isPaused]);

  return (
    <>
      <ARInteractive
        mainCamera={mainCamera}
        size={dreamerSize[dreamerSizeState]}
        setFindSurface={setFindSurface}
        findSurface={findSurface}
        scene={scene}
      />
      {findSurface && (
        <>
          <Button
            position={[buttonPos[0] - 0.2, buttonPos[1], buttonPos[2]]}
            rotation={buttonRotations}
            onSelect={() => {}}
          >
            Surface Mode
          </Button>
        </>
      )}
    </>
  );
}

export default Dreamer;
