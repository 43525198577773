import { Interactive, useHitTest } from '@react-three/xr';
import { useRef } from 'react';
import { Vector3, Euler } from 'three';
import { dreamerIsBeingTouched, setDreamerIsBeingTouched } from '.';

export function onHoverModel(scene, findSurace, setFindSurface) {
  setDreamerIsBeingTouched(true);
  if (findSurace) setFindSurface(false);
}

export function onBlurModel() {
  setDreamerIsBeingTouched(false);
}

export function findSurfaceOnSelect(findSurface, setFindSurface) {
  if (!dreamerIsBeingTouched) setFindSurface(false);
  setDreamerIsBeingTouched(false);
}
