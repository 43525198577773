

function Loading() {

    return (
        <div className="loading-wrapper">
            <h1 className="load-text">Loading...</h1>
            <div className="spinner"></div>
        </div>
    )
}

export default Loading