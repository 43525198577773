import { renderer } from '../components/dreamer';
import { saveAsImage } from './';

export function createPFP(camera, setTargetY) {
  const lastCamera = camera.clone();
  // const lastViewport = renderer.getSize();
  const lastSize = { width: window.innerWidth, height: window.innerHeight };
  const lastFov = camera.fov;

  camera.scale.set(camera.scale.x, camera.scale.y, camera.scale.z * 1.5);
  camera.position.set(camera.position.x, 3.2, camera.position.z + 1);
  setTargetY(4);

  camera.fov = window.innerWidth > 500 ? 25 : 30;

  setTimeout(() => {
    saveAsImage('headshot', { x: 1024, y: 1024 }, camera);
    camera.position.copy(lastCamera.position);
    camera.rotation.copy(lastCamera.rotation);
    camera.scale.copy(lastCamera.scale);
    camera.updateProjectionMatrix();
    camera.fov = lastFov;
    renderer.setSize(lastSize.width, lastSize.height);
    setTargetY(2.5);
  }, 25);
}
