import { useFrame } from '@react-three/fiber';
import { useHitTest } from '@react-three/xr';
import { useEffect, useRef } from 'react';
import { Vector3, Euler } from 'three';
import { waitForARButtonAndHide } from '../App';
import { findSurfaceNoXandY, mouse, raycaster } from '../handlers/clickScreen';

export function Model({ scene, findSurface, size, camera }) {
  const ref = useRef();

  useFrame(() => {
    // waitForARButtonAndHide();
    if (findSurface) {
      // scene.lookAt(new Vector3(0, scene.position.y, 0));
      // scene.rotation.set(0, 0, 0);
      // scene.rotateY(Math.PI);
    }
  });

  useHitTest((hit) => {
    if (!findSurface) return;

    hit.decompose(
      ref.current.position,
      new Euler(scene.rotation.x, scene.rotation.y, scene.rotation.z, 'XYZ'),
      new Vector3(...size)
    );

    //Make look at camera when you click on the model
    // scene.lookAt(
    //   new Vector3(
    //     ref.current.position.x,
    //     camera.position.y + ref.current.position.y,
    //     ref.current.position.z
    //   )
    // );

    scene.rotation.y =
      Math.atan2(
        camera.position.x - scene.position.x,
        camera.position.z - scene.position.z
      ) + Math.PI;

    // scene.rotateY(Math.PI);

    if (findSurfaceNoXandY) {
      ref.current.position.set(mouse.x, mouse.y, ref.current.position.z);
    }
  });

  return (
    <primitive
      ref={ref}
      castShadow={true}
      object={scene}
      scale={[0.04, 0.04, 0.04]}
      rotation={[0, 3.1, 0]}
    />
  );
}
